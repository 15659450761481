/* jshint ignore:start */
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        echo.init({
          offset: 1000,
          throttle: 250,
          unload: false,
          callback: function (element, op) {
            // console.log(element, 'has been', op + 'ed');
            var myEvent = new CustomEvent("lazyLoadComplete");
            document.body.dispatchEvent(myEvent);
          }
        });
		  
        // add this function for a fixed header
        /*$(window).scroll(function(){
          if ($(window).scrollTop() >= 5) {
            $('body').addClass('fixed-header');
          }
          else {
            $('body').removeClass('fixed-header');
          }
        }); */
		  
        // slick on the homepage if needed
        /* $('.slick').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }); */

		  
		  
    	// Gallery with Filters page
		$("#filterContainer select").change(function(){	
			$("div.message-empty").removeClass("show");
			var filtercolor = $("#filterColor option:selected").val();
			var filterstyle = $("#filterStyle option:selected").val();
			console.log("Style: " + filterstyle + " Color: " + filtercolor);				
			$("div.filterDiv").removeClass("show");
			$("div.filterDiv."+filterstyle+"."+filtercolor).addClass("show");
			var matched = $("div.filterDiv."+filterstyle+"."+filtercolor);
			var numMatched = matched.length;
			console.log("Number: " + numMatched);				
			if (numMatched < 1) {
				console.log("NO MATCHES");	
				//alert 'No Matches';
				$("div.message-empty").addClass("show");
			}		
		});			  
		  
		/**** PRODUCT CAROUSEL ****/
        $('.product-carousel').slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
		  rows: 0, // Fix for Slick v1.8.0-1
		  responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 3,
		        infinite: true,
		        dots: true
		      }
		    },
		    {
		      breakpoint: 600,
		     settings: {
		        slidesToShow: 2,
		        slidesToScroll: 2,
		        infinite: true,
		        dots: true				
		      }
		    }
		  ]
        });		  
		  
		  
        /**** ACCORDION CODE ****/
        $('.accordion-label').click(function() {
          var content = this.nextElementSibling;
          $('.accordion-label').not($(this)).removeClass('active').attr('aria-expanded', 'false');
          $('.accordion-content').css('max-height', '');
          if ($(this).is('.active')) {
            $(this).toggleClass('active').attr('aria-expanded', 'false');
            heightVal = '0';
          } else {
            $(this).toggleClass('active').attr('aria-expanded', 'true');
            heightVal = content.scrollHeight + "px";
          }
          $(content).css('max-height', heightVal);
        }); 


        /**** EKKO LIGHTBOX ****/		  
        $(document).on('click', '[data-toggle="lightbox"]', function(event) {
          event.preventDefault();
          $(this).ekkoLightbox({
            alwaysShowClose: true,
          });
        });
		  
		  
        /**** PRODUCT GALLERY WITH THUMBNAILS ****/			  
		/* product js for slick carousel */ 
        $('#product-image-gallery').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          swipe: false,
          arrows: false,
		  //arrows: true,
          fade: true,
          asNavFor: '#product-image-gallery-nav',
          speed: 200
        });
        $('#product-image-gallery-nav').slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: true,
          asNavFor: '#product-image-gallery',
          dots: false,
          centerMode: false,
          centerPadding: 0,
          focusOnSelect: true,
          speed: 200
        });			  
		  
		  
		  
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
         
        /**** TABS ****/
        $('.tab-set li').on('click', function(e){
          var tab_id = $(this).attr('data-tab');
          //tab_id = tab_id.replace(/\s+/g, '-').toLowerCase();
          $('.tab-set li').removeClass('active');
          $('.tab-content div').removeClass('active');
          $(this).addClass('active');
          $("#tab_"+tab_id).addClass('active');
        });
		  


        /**** smooth anchor scrolling ****/
        var $root = $('html, body');

        $('a[href^="#"]').click(function () {
          $root.animate({
            scrollTop: $( $.attr(this, 'href') ).offset().top
          }, 500);

          return false;
        });
		  
        		
		  
      }
    },
    // Home page
    'home': {
      init: function() {   // JavaScript to be fired on the home page
         
        $('.home-slideshow').slick({
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
		  dots: true,
		  rows: 0 // Fix for Slick v1.8.0-1
        });        
	  
        $('.testimonial-slideshow').slick({
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
		  rows: 0 // Fix for Slick v1.8.0-1
        });
      },
      finalize: function() {  // JavaScript to be fired on the home page, after the init JS
		  
      }
    },
	  
	  
	  
    // Noli Cabinets page
    'kitchen_cabinets': {
      init: function() {
        //console.log("THIS IS THE CABINETS PAGE");
        $('.cabinets-slider').slick({
		  slidesToShow: 1,
		  slidesToScroll: 1,
		  arrows: false,
		  fade: true,
		  asNavFor: '.slider-nav'
        });	
	  
      },
      finalize: function() {
        // JavaScript to be fired on the page, after the init JS
      }
    },	  
	  
	// Noli Portfolio page  
    'portfolio': {
      init: function() {
        //console.log("PORTFOLIO PAGE");
		$('.responsive').slick({
		  dots: true,
		  infinite: false,
		  speed: 300,
		  slidesToShow: 3,
		  slidesToScroll: 3,
		  responsive: [
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		  ]
		});  
      },
      finalize: function () {
      }
    },	 
	  
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {  // JavaScript to be fired on the about us page
        
      }
    },
	  
	  
    // Carousels page
    'carousels': {
      init: function() {  // JavaScript to be fired on the carousels page

      }
    },
	  
	  
    // Accordions and Tabs page
    'accordions_tabs': {
      init: function() {

      },
      finalize: function() {
        (function() {
          'use strict';

          function trackScroll() {
            var scrolled = window.pageYOffset;
            var coords = document.documentElement.clientHeight;

            if (scrolled > coords) {
              goTopBtn.classList.add('back_to_top-show');
            }
            if (scrolled < coords) {
              goTopBtn.classList.remove('back_to_top-show');
            }
          }

          function backToTop() {
            if (window.pageYOffset > 0) {
              window.scrollBy(0, -80);
              setTimeout(backToTop, 0);
            }
          }

          var goTopBtn = document.querySelector('.back_to_top');

          window.addEventListener('scroll', trackScroll);
          goTopBtn.addEventListener('click', backToTop);
        })();
      }
    },
	  
    // Gallery with Filters page
    'Xgallery_with_filters': {
      init: function() {

		$(".filterDiv").click(function(){
			console.log("Clicked");
		});		  
      },		  
      finalize: function () {
      }		  
     },		  
	  
	  
    // Gallery/Portfolio page
    'galleryportfolio': {
      init: function() {

        // Size grid parents by content
        function masonrySizer() {
          $.each($('.grid').find('img'), function(i,v) {
            var $ele = $(this),
                $parent = $ele.parent();

            $parent.height('auto'); // reset parent height or expanding upwards will bug out due to ele height restricting image height

            var $eleHeight = $ele.height(),
                $eleWidth = $ele.width();

            $parent.height($eleHeight); // Set parent height to image height
          });
        }

        function startMasonry() {
          $('.grid').masonry({
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer'
          });
        }

        $( window ).resize(function() {
          masonrySizer();
        });

        $(document).ready(function(){
          masonrySizer();
        });


        document.body.addEventListener("lazyLoadComplete", startMasonry, false);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


  };


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
